import {
    Box,
    Button,
    Grid,
    IconButton,
    Stack,
    styled,
    Typography,
    useMediaQuery,
} from '@mui/material';
import React from 'react';
import bg from "./../../assets/image/home/footerBg.png";
import arrow from "./../../assets/image/home/arrow.png";


import { faEnvelope, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import fbIcon from "../../assets/icons/fb-transparent.png";
import twitterIcon from "../../assets/icons/twetter-transparent.png";
import instragramIcon from "../../assets/icons/instragram-transparent.png";
import '../../scss/HomeLayout/footer.scss';
import send from "../../assets/icons/send.png";
const socialInfo = [
    {
        icon: instragramIcon,
        url: "https://www.instagram.com/programminghero/",
    },
    {
        icon: fbIcon,
        url: "https://www.facebook.com/programmingherowebcourse",
    },
    {
        icon: twitterIcon,
        url: "https://twitter.com/programminghero",
    }
];


const StyledTypographyLabel = styled(Typography)(({ theme }) => ({
    fontFamily: 'Space Grotesk',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 16,
    lineHeight: 2,
    textTransform: 'capitalize',
    color: '#EEE0FF',
    textAlign: 'start',
    zIndex: 9,
    // ml: 1.5,
    display: 'block',
    // mt: 1,
    [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        ml: 0,
        display: 'none',
    },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
    fontFamily: 'Space Grotesk',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 16,
    lineHeight: 2,
    textTransform: 'capitalize',
    color: '#EEE0FF',
    textAlign: 'start',
    zIndex: 9,
    ml: 1.5,
    [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        ml: 0,
    },
    [theme.breakpoints.only('xs')]: {
        fontSize: 11,
    },
}));

const StyledSocialIconBox = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

const StyledTypographyForDivider = styled(Typography)(({ theme }) => ({
    fontWeight: 600,
    fontSize: 12,
    color: '#EEE0FF',
    display: 'none',
    [theme.breakpoints.down('sm')]: {
        display: 'block',
    },
}));

const StyledButton = styled(Button)(({ theme }) => ({
    fontFamily: 'Space Grotesk',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 1.5,
    textTransform: 'capitalize',
    color: '#EEE0FF',
    [theme.breakpoints.down('sm')]: {
        fontSize: 14,
        lineHeight: 2,
    },
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'row',
        // justifyContent: 'space-evenly'
    },
}));

const StyledTypographyCaption = styled(Typography)(({ theme }) => ({
    fontFamily: 'Space Grotesk',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    textTransform: 'capitalize',
    lineHeight: 1.8,
    background: 'linear-gradient(97.64deg, #EAAAFF 15.56%, #B5ACFF 92.85%)',
    backgroundClip: 'text',
    textFillColor: 'transparent',
    zIndex: 9,
    [theme.breakpoints.down('sm')]: {
        fontSize: 12,
        lineHeight: 2,
    },
    [theme.breakpoints.only('xs')]: {
        fontSize: 11,
    },
}));

const Footer = () => {
    const smartLogic = useMediaQuery('(min-width: 1441px)');

    return (
        <Box
            id="footer"
            sx={{
                position: 'relative',
                zIndex: 1,
                mt: { xs: -1, sm: -2, md: -4, lg: -8, xl: smartLogic ? 0 : -10 },
                backgroundColor: '#010313',
            }}
        >
            <Box
                sx={{
                    backgroundImage: `url('${bg}')`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '100%',
                    backgroundPosition: 'center 50vh',
                    pb: 10
                }}
            >
                {/*Gradient Effect*/}
                <Box
                    sx={{
                        position: 'relative',
                        pointerEvents: "none",
                        top: 100,
                        mb: -10,
                        left: '0px',
                        width: '54vw',
                        height: '20vw',
                        background:
                            'linear-gradient(228.02deg, #F236FF 16.1%, rgba(92, 82, 253, 0.67) 60.73%)',
                        filter: 'blur(200px)',
                        transform: 'matrix(0.99, 0.09, -0.18, 1, 0, 0)',
                    }}
                ></Box>

                <div className="footer-component">
                    <div className="container mt-4">
                        <div className="row footer-section">
                            <div className="col-lg-3 col-md-6">
                                <div className="mt-4">
                                    <Typography variant='h2' sx={{ fontSize: 38, color: "#fff" }}>Job Placement</Typography>
                                    <p className="items-uniq">Check out our documentation and start integrating today. We can't wait to see what you'll build.</p>
                                    <div className="social-links">
                                        {
                                            socialInfo.map(({ icon, url }, i) => (
                                                <a key={i} href={url} target="_blank" rel="noreferrer">
                                                    <img src={icon} alt="" />
                                                </a>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 d-md-flex justify-content-center">
                                <div className="mt-4">
                                    <h3 className="label">About Us</h3>
                                    <div className="d-flex flex-column">
                                        <Link to="/about-us" className="items py-2">
                                            Our Team
                                        </Link>
                                        <a className="items py-2" href='https://web.programming-hero.com/blog' target="_blank" rel="noreferrer">
                                            Blog
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 d-lg-flex justify-content-center">
                                <div className="mt-4  ">
                                    <h3 className="label">Explore</h3>
                                    <div className="d-flex flex-column">
                                        <Link to="/success-students" className="items py-2">
                                            Success Stories
                                        </Link>
                                        <a href='https://web.programming-hero.com/home/' className="items py-2" target="_blank" rel="noreferrer">
                                            Learning platform
                                        </a>
                                        <Link to="/project-showcasing" className="items py-2">
                                            Project showcasing
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 d-lg-flex justify-content-end">
                                <div className='contact-card mt-4'>
                                    <h3 className="label pb-3">Contact Us</h3>
                                    <p className="items"><FontAwesomeIcon className="icon me-2" icon={faLocationDot} />Level-4, 34, Awal Centre, Banani, Dhaka</p>
                                    <p className="items"><FontAwesomeIcon className="icon me-2" icon={faEnvelope} />jobs@programming-hero.com</p>
                                    <div>
                                        <p className="items-uniqe">Drop us your mail...</p>
                                        <div className="send-section">
                                            <input className="input-box w-100 px-4 py-3" type="text" placeholder="Email Address" />
                                            <Button className='send-button'>
                                                <img src={arrow} alt="" />
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Box>
        </Box >
    );
};

export default Footer;
