import React, { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

const MasterLayout: FC<{ children: JSX.Element }> = ({ children }) => {
  const location = useLocation();
  useEffect(() => window.scrollTo(0, 0), [location]);
  const hideForAdministrator = ((!location?.pathname?.includes('dashboard')));

  return (
    <>
      {hideForAdministrator && <Header />}
      {children}
      {hideForAdministrator && <Footer />}
    </>
  );
};

export default MasterLayout;
