import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import './scss/main.scss';
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./lib/store";
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

export const queryClient = new QueryClient()

root.render(
  <QueryClientProvider client={queryClient}>
    <React.StrictMode>
      <Provider store={store}>
        <App />
        {process.env.NODE_ENV === 'development' &&
          <ReactQueryDevtools />
        }
      </Provider >
    </React.StrictMode>
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
