import React, { useState } from 'react';
import "../../../scss/form/forgotPassword.scss";
import { useForm } from 'react-hook-form';
import swal from 'sweetalert';
import authService from '../../../services/auth/authService';
import regex from '../../../utilities/regex';
import { useLocation, useNavigate } from 'react-router-dom';

const ForgotPassForm = (props) => {
    const { register, handleSubmit, watch, reset, setValue, formState: { errors } } = useForm();
    const NavigateTo = useNavigate();
    const location = useLocation();
    const queryToken = location.search.replace('?code=', '')

    const onSubmit = (data) => {
        if (props.label === "Forgot password") {
            authService.forgotPassword(data.email)
                .then(res => {
                    if (res.data.success) {
                        swal({
                            title: "Check your email",
                            text: "Recovery link has been sent to your email",
                            icon: "success"
                        })
                    }
                })
                .catch(err => {
                    swal("", 'Could not find user', "error")
                })
        }
        if (props.label === "Reset password") {
            authService.resetPassword(queryToken, data.password)
                .then(res => {
                    if (res?.data?.success) {
                        swal({
                            title: "Want to Login?",
                            text: "Your password has been reset successfully!",
                            icon: "success",
                            buttons: "Yes",
                            dangerMode: true,
                        })
                            .then((isSure) => {
                                if (isSure) {
                                    NavigateTo("/login")
                                }
                            }).catch(error => {
                                swal("", "reset failed", "error");
                            });
                    }
                })
        }

    }

    return (
        <div className="forgot-password">
            <div className="container mt-5 pt-5 d-flex justify-content-center align-items-center">
                <div className="card mt-4 px-5 pb-4 ">
                    <form onSubmit={handleSubmit(onSubmit)} className="pt-4 py-md-5">
                        <h2 className="mb-4">{props.label}</h2>
                        {
                            props.label === "Forgot password" && (
                                <div>
                                    <label className="sr-only" htmlFor="email">Email </label>
                                    <input id="email" onBlur={e => setValue("email", watch("email")?.trim())} placeholder="Email" type="text" className="form-control w-100" {...register('email', { required: true, pattern: regex.email })} />
                                    {errors.email &&
                                        <span className="text-danger">
                                            {errors.email.type === "pattern" ? "Valid email required!" : "Email is Required!"}
                                        </span>
                                    }
                                </div>
                            )
                        }

                        {

                            props.label === "Reset password" && (
                                <>
                                    <div>
                                        <label className="sr-only" htmlFor="email">New password</label>
                                        <input placeholder="New password" id="password" type="password" className="form-control" {...register('password', { required: true, pattern: regex.password })} />
                                        {errors.password &&
                                            <span className="text-danger">
                                                {errors.password.type === "pattern" ? "Password must be 6 to 15 characters which contain at least one numeric digit and a special character!" : "Password is Required!"}
                                            </span>
                                        }
                                    </div>

                                    <div className="mt-3">
                                        <label className="sr-only" htmlFor="confirm_password">Confirm Password</label>
                                        <input placeholder="Confirm password" id="confirm_password" type="password" className="form-control" {...register("confirm_password", { required: true, pattern: regex.password })} />
                                        {errors.confirm_password &&
                                            <span className="text-danger">
                                                {errors.confirm_password.type === "pattern" ? "Password must be 6 to 15 characters which contain at least one numeric digit and a special character!" : "Password is Required!"}
                                            </span>
                                        }
                                        {
                                            watch("confirm_password") !== "" &&
                                            watch("password") !== watch("confirm_password") &&
                                            <span className="text-danger">
                                                The password and confirmation password do not match.
                                            </span>
                                        }

                                    </div>
                                </>

                            )
                        }


                        <div className="form-group pt-4 ">
                            <button type="submit" className="btn btn-block btn-danger francy-btn w-100">
                                <span>Next</span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassForm;