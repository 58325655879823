import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import MasterLayout from "./components/Common/MasterLayout";
import { loadClientRoutes } from "./lib/Slicers/clientRouteSlice";
import userServices from "./services/userServices";
import "./scss/dashboardLayout/scss/style.scss";
import "./styles/dashboardLayout.css";
import Preloader from "./components/Preloader/Preloader";
import Lazyloading from "./utilities/lazyLoading";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import getRoutes from "./routes";
import { setUserData } from "./lib/Slicers/authSlice";
import HiredForm from "./pages/HiredForm/HiredForm";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import TokenCheck from "./pages/Auth/TokenCheck";
import ResetPassword from "./pages/Auth/ResetPassword";
import ThemeWrapper from "./theme/ThemeWrapper";
import { CssBaseline } from "@mui/material";

//lazy loaded page
const Dashboard = Lazyloading(() => import("./pages/Dashboard/Dashboard"), {
  fallback: <Preloader />,
});
const InstructorDashboard = Lazyloading(
  () => import("./containers/InstructorLayout"),
  {
    fallback: <Preloader />,
  }
);
const HomePage = Lazyloading(() => import("./pages/HomePage"), {
  fallback: <Preloader />,
});
const ProjectShowCasing = Lazyloading(
  () => import("./pages/ProjectShowCasing"),
  {
    fallback: <Preloader />,
  }
);
const SuccessStudents = Lazyloading(() => import("./pages/SuccessStudents"), {
  fallback: <Preloader />,
});
const AboutUs = Lazyloading(() => import("./pages/AboutUs"), {
  fallback: <Preloader />,
});
const ContactUs = Lazyloading(() => import("./pages/ContactUs"), {
  fallback: <Preloader />,
});

const Login = Lazyloading(() => import("./pages/Auth/Login"), {
  fallback: <Preloader />,
});

const App: FC = () => {
  const clientRoutes = useSelector(
    (state: any) => state.clientRoute?.clientRoute
  );
  const [routes, setRoutes] = useState([]);

  useEffect(() => {
    setRoutes(getRoutes(clientRoutes?.route));
  }, [clientRoutes]);

  // Get user data on reload and set to redux state
  const dispatch = useDispatch();
  useEffect(() => {
    userServices
      .getLoggedUserInfo()
      .then((response) => {
        dispatch(loadClientRoutes(response?.data?.clientRoute));
        dispatch(setUserData(response?.data?.user));
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          console.log(err);
        }
      });
  }, []);

  type routeType = {
    path: string;
    name: string;
    component: any;
  };

  return (
    <ThemeWrapper>
      <CssBaseline />
      <BrowserRouter>
        <MasterLayout>
          <Routes>
            {/* //dashboard layout from @coreui [make sure this componet call offside the route ]  */}
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            >
              {routes?.map(
                (route: routeType, idx) =>
                  route.component && (
                    <Route
                      key={idx}
                      path={route?.path}
                      element={<route.component />}
                    />
                  )
              )}
            </Route>

            <Route
              path="/instructor-dashboard"
              element={
                <PrivateRoute>
                  <InstructorDashboard />
                </PrivateRoute>
              }
            >
              {routes?.map(
                (route: routeType, idx) =>
                  route.component && (
                    <Route
                      key={idx}
                      path={route?.path}
                      element={<route.component />}
                    />
                  )
              )}
            </Route>
            {/* end   */}

            <Route path="/" element={<HomePage />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/success-students" element={<SuccessStudents />} />
            <Route path="/success-students" element={<SuccessStudents />} />
            <Route path="/project-showcasing" element={<ProjectShowCasing />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/token-check" element={<TokenCheck />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/hired-form" element={<HiredForm />} />
          </Routes>
        </MasterLayout>
      </BrowserRouter>
    </ThemeWrapper>
  );
};

export default App;
