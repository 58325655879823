import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import "../../scss/HiredForm/upload-file.scss";
import pdfIcon from "../../assets/icons/pdfIcon.png";
import successIcon from "../../assets/icons/success.png";
import cancleIcon from "../../assets/icons/trash-transparent.png";
import "../../scss/HiredForm/upload-file.scss";
import CircularProgressBar from "./CircularProgressBar";


const UploadFile = (props) => {

    const onDrop = useCallback((acceptedFiles) => {
        handleFileChange(acceptedFiles);
    }, []);

    const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({ onDrop });


    const handleFileChange = (files) => {
        props.handleAddAttachment(files?.[0]);
    };


    return (
        <div className="upload-file-component">
            <div className="drop-file p-5">
                {
                    props.isUploading ?

                        <div class="d-flex justify-content-center uplodding-mode">
                            <div className="text-center">
                                <CircularProgressBar
                                    progress={props.percentage}
                                />
                                <p>Uploading file</p>
                                <span className="btn cancle-btn">Cancel</span>
                            </div>
                        </div>

                        :

                        props?.attachment?.length ?
                            <>
                                {
                                    props?.attachment?.map((element) => (
                                        <div className="letter-preview">
                                            <div className="text-center">
                                                <img
                                                    src={successIcon}
                                                    alt=""
                                                    style={{ width: '60px' }}
                                                />

                                                <div className="mt-3">
                                                    <img
                                                        style={{ cursor: 'pointer', width: '25px' }}
                                                        src={pdfIcon}
                                                        onClick={() => props?.handleDownloadAttachment(element?.fileId || element?._id)}
                                                        alt=""


                                                    />
                                                    <p className="document-title d-inline ms-2">
                                                        {element?.name}
                                                    </p>

                                                    <img
                                                        className="ms-2 mb-1"
                                                        src={cancleIcon}
                                                        style={{ cursor: 'pointer', width:'20px' }}
                                                        onClick={(event) =>
                                                            props?.handleDeleteAttachment(element?.fileId)
                                                        }
                                                        alt=""
                                                    />

                                                </div>

                                            </div>
                                        </div>
                                    ))}
                            </>

                            :

                            <div {...getRootProps()} className="p-3 text-center w-100">
                                <input {...getInputProps()} />

                                <img
                                    width={63}
                                    src={require("../../assets/icons/file.png")}
                                    alt=""
                                />
                                {isDragActive ? (
                                    <h3 className="py-3 upload-label">
                                        Drop the files here ...
                                    </h3>
                                ) : (
                                    <>
                                        <h3 className="pt-3 upload-label">
                                            Drag & Drop files or{" "}
                                            <span className="text-primary">Browse</span>
                                        </h3>
                                        <p className="upload-notice">Support pdf and jpg files</p>
                                    </>

                                )}
                            </div>

                }

            </div>
        </div>
    );
};

export default UploadFile;