import { createTheme, ThemeProvider } from '@mui/material';
import React from 'react';

const ThemeWrapper = ({ children }) => {
    const theme = createTheme({
        typography: {
            fontFamily: "Space Grotesk",
            h1: {
                fontWeight: 700
            },
            h2: {
                fontWeight: 700
            },
            h3: {
                fontWeight: 700
            },
            h4: {
                fontWeight: 700
            },
            h5: {
                fontWeight: 700
            },
            h6: {
                fontWeight: 700
            },
        }
    })
    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    );
};

export default ThemeWrapper;