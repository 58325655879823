import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import "../../scss/Custom/custom-modal.scss";

export default function CustomModal(props) {

	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		bgcolor: 'background.paper',
		boxShadow: 24,
		outline: 0,
		borderRadius: 2,
		p: 4,
		width: "80%",
		maxWidth: 800,
		...props?.customStyle
	};

	const iconStyle = {
		position: 'absolute',
		top: -10,
		right: -10,
		fontSize: '25px',
		textAlign: 'center',
		color: 'white',
		background: '#ff3838',
		width: "40px",
		height: "40px",
		borderRadius: "50%",
		cursor: 'pointer'
	};


	const [modalIsOpen, setIsOpen] = useState(false);

	function openModal() {
		setIsOpen(true);
		if (props.clearState) {
			props.clearState();
		}
	}

	useEffect(() => {
		if (props.openModal && props.openModal.edit) {
			openModal()
			if (props.clearState) {
				props.clearState();
			}
		}

	}, [props.openModal])


	useEffect(() => {
		if (props.closeModal) {
			if ((props.closeModal.status === false && modalIsOpen) || (props.closeModal.edit === false && modalIsOpen)) {
				console.log('close modal called', props.closeModal);
				setIsOpen(props.closeModal.edit);
			}
		}

	}, [props.closeModal])


	function closeModal() {
		console.log('closeModal called');
		setIsOpen(false);
		if (props.handleCloseModal) {
			console.log('handleCloseModal called');
			props.handleCloseModal();
		}
	}


	return (
		<Modal
			keepMounted
			open={modalIsOpen}
			onClose={closeModal}
			aria-labelledby="keep-mounted-modal-title"
			aria-describedby="keep-mounted-modal-description"
			className="custom-modal-componet"
		>

			<Box sx={style}>
				<Box sx={iconStyle}
					onClick={() => closeModal()}
				>
					<FontAwesomeIcon icon={faTimes} />
				</Box>
				<Box className="custom-modal-box">
					{
						props.children
					}
				</Box>
			</Box>
		</Modal>
	);
}
