import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../../scss/HomeLayout/header.scss";

const navItems = [
    {
        label: "Home",
        path: "/"
    },
    {
        label: "About us",
        path: "/about-us"
    },
    {
        label: "Success Students",
        path: "/success-students"
    },
    {
        label: "Project showcasing",
        path: "/project-showcasing"
    },
    {
        label: "Contact",
        path: "/contact"
    }
];

const Header: FC = () => {
    const { pathname } = useLocation();
    const [headerPosition, setHeaderPosition]: any = useState(false);

    //nav sticky

    useEffect(() => {
        var prevScrollpos = window.pageYOffset;

        window.addEventListener("scroll", () => {
            var currentScrollPos = window.pageYOffset;
            if (prevScrollpos > currentScrollPos) {
                if (window.scrollY > 85) {
                    setHeaderPosition("sticky");
                } else {
                    setHeaderPosition("static");
                }
            } else {
                setHeaderPosition("hidden");
            }
            prevScrollpos = currentScrollPos;
        });
    }, []);

    //end

    // const userData = useSelector((state: any) => state.auth?.token);
    // const handleRedirect = () => {
    //   navigateTo("/dashboard/home");
    //   window.location.reload();
    // }

    return (
        <>
            <div className="header-component">
                <nav
                    className={` ${
                        headerPosition === "hidden" ? "d-none" : headerPosition === "sticky" ? "sticky" : ""
                    } static navbar navbar-expand-xl navbar-light fixed-top py-3`}
                >
                    <div className="container">
                        <Link to="/" className="navbar-brand">
                            Job placement
                        </Link>
                        <button
                            className="navbar-toggler shadow-none text-white"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarNav"
                            aria-controls="navbarNav"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <svg
                                height={35}
                                width={35}
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                                />
                            </svg>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav ms-auto">
                                {navItems.map(({ label, path }, i) => (
                                    <li key={i} className="nav-item">
                                        <Link
                                            to={path}
                                            className={`nav-link  ${pathname === path && "active"}`}
                                        >
                                            {label}
                                        </Link>
                                    </li>
                                ))}

                                {/*{
                  userData?.token ?
                    <li className="nav-item">
                      <Link
                        onClick={() => handleRedirect()}
                        to=""
                        // to="/dashboard/home"
                        className="nav-link"
                      >
                        Dashboard
                      </Link>
                    </li>
                    :
                    <>
                      <li className="nav-item log-reg-nav-item me-lg-2 me-md-2 ">
                        <Link
                          to="/login"
                          className="nav-link"
                        >
                          Login
                        </Link>
                      </li>
                       <li className="nav-item log-reg-nav-item mt-lg-0 mt-md-0 mt-2">
                        <Link
                          to="/register"
                          className="nav-link"
                        >
                          Register
                        </Link>
                      </li>
                    </>
                }*/}

                                <li className="nav-item log-reg-nav-item me-lg-2 me-md-2 ">
                                    <Link
                                        to="/hired-form"
                                        className={`nav-link  ${pathname === "/hired-form" && "active"}`}
                                    >
                                        Got Hired
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </>
    );
};

export default Header;
