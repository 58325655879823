import React from "react";
const Dashboard = React.lazy(() => import("./components/Dashboard/Dashboard"));
const InstructorDashboard = React.lazy(() => import("./components/Dashboard/InstructorDashboard"));
const Header = React.lazy(() => import("./components/Dashboard/Header/Header"));
const ProgramsManage = React.lazy(() => import("./components/Dashboard/Programs/ManagePrograms"));
const AddPrograms = React.lazy(() => import("./components/Dashboard/Programs/AddPrograms"));
const TechnologyStack = React.lazy(() => import("./components/Dashboard/TechnologyStack/TechnologyStack"));
const SuccessStoryStats = React.lazy(() =>
    import("./components/Dashboard/SuccessStoryStats/SuccessStoryStats")
);
const PlacedStudents = React.lazy(() => import("./components/Dashboard/PlacedStudents/PlacedStudents"));
const PreviewPrograms = React.lazy(() => import("./components/Dashboard/Programs/PreviewPrograms"));
const ClientFeedback = React.lazy(() => import("./components/Dashboard/ClientFeedback/ClientFeedback"));
const ClientManagement = React.lazy(() => import("./components/Dashboard/ClientFeedback/ClientManagement"));
const ContactUs = React.lazy(() => import("./components/Dashboard/Contract/Contract"));
const ContactInfo = React.lazy(() => import("./components/Dashboard/Contract/ContactInfo"));
const AddProject = React.lazy(() => import("./components/Dashboard/Project/AddProject"));
const ManageProject = React.lazy(() => import("./components/Dashboard/Project/ManageProject"));
const AddIndustry = React.lazy(() => import("./components/Dashboard/IndustryManagement/AddIndustry"));
const ManageIndustry = React.lazy(() => import("./components/Dashboard/IndustryManagement/ManageIndustry"));
const UserManagement = React.lazy(() => import("./components/Dashboard/UserManagement/User"));
const Profile = React.lazy(() => import("./components/Dashboard/UserProfile/Profile"));
const GotHired = React.lazy(() => import("./components/Dashboard/GotHired/GotHired"));
const EditGotHiredInfo = React.lazy(() => import("./components/Dashboard/GotHired/EditModal"));
const PreviewGotHiredInfo = React.lazy(() => import("./components/Dashboard/GotHired/PreviewModal"));
const MergeGotHiredInfo = React.lazy(() => import("./components/Dashboard/GotHired/MergeModal"));
const ManagePoster = React.lazy(() => import("./components/Dashboard/ManagePoster/ManagePoster"));
const EditPoster = React.lazy(() => import("./components/Dashboard/ManagePoster/EditPoster"));
const PosterTemplate = React.lazy(() => import("./components/Dashboard/ManagePoster/PosterTemplate"));
const TemplatePreview = React.lazy(() => import("./components/Dashboard/ManagePoster/TemplatePreview"));
const ClientDirectory = React.lazy(() => import("./components/Dashboard/ClientDirectory/ClientDirectory"));
const AddCompany = React.lazy(() => import("./components/Dashboard/ClientDirectory/AddCompany"));
const CompanyProfile = React.lazy(() => import("./components/Dashboard/ClientDirectory/CompanyProfile"));
const AddClient = React.lazy(() => import("./components/Dashboard/ClientDirectory/AddClient"));
const ClientProfile = React.lazy(() => import("./components/Dashboard/ClientDirectory/ClientProfile"));
const InstructorClientDirectory = React.lazy(() =>
    import("./components/Dashboard/InstructorDashboard/ClientDirectory/InstructorClientDirectory")
);
const ClientDirectoryTotalCVSend = React.lazy(() =>
    import("./components/Dashboard/ClientDirectory/ClientProfileInfo/TotalCVSend")
);
const ClientDirectoryTotalHired = React.lazy(() =>
    import("./components/Dashboard/ClientDirectory/ClientProfileInfo/TotalHired")
);

const components = {
    Dashboard,
    Header,
    ProgramsManage,
    AddPrograms,
    TechnologyStack,
    SuccessStoryStats,
    PlacedStudents,
    PreviewPrograms,
    ClientFeedback,
    ClientManagement,
    ContactUs,
    ContactInfo,
    AddProject,
    ManageProject,
    AddIndustry,
    ManageIndustry,
    UserManagement,
    Profile,
    GotHired,
    EditGotHiredInfo,
    PreviewGotHiredInfo,
    MergeGotHiredInfo,
    ManagePoster,
    EditPoster,
    PosterTemplate,
    TemplatePreview,
    ClientDirectory,
    AddCompany,
    CompanyProfile,
    AddClient,
    ClientProfile,
    InstructorDashboard,
    InstructorClientDirectory,
    ClientDirectoryTotalCVSend,
    ClientDirectoryTotalHired
};

const stringToComponent = (stringComponentName) => {
    return components[stringComponentName];
};

const getRoutes = (routesFromBackend) => {
    const routes = routesFromBackend?.map((route) => {
        return { ...route, component: stringToComponent(route.component) };
    });
    return routes;
};

export default getRoutes;
