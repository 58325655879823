import React from 'react';
import ForgotPassForm from '../../components/Forms/ForgotPassForm/ForgotPassForm';

const ResetPassword = () => {
    return (
        <>
            <ForgotPassForm
                label="Reset password"
            />
        </>
    );
};

export default ResetPassword;