import axios from "axios";
import { detectOS } from "../../utilities/deviceDetect";
import jwt_decode from "jwt-decode";
import fingerPrintVisitorId from "../../utilities/fingerPrint";





const getAuthorization = () => {
  // Getting user token and set to localstorage
  const localStorageData = JSON.parse(localStorage.getItem("user")) || {};
  const token = localStorageData && localStorageData["token"];
  axios.defaults.headers.common = { Authorization: `${token}` };
};

const API_URL = process.env.REACT_APP_API_URL + "user";

class AuthService {
  constructor() {
    getAuthorization();
  }



  // Register
  register(fullName, email, phone, password, token, redirect) {
    return axios
      .post(API_URL + "/register", {
        fullName,
        email,
        phone,
        password
      })
      .then(async (response) => {
        if (response.data?.success && redirect) {
          await localStorage.setItem("user", JSON.stringify(response.data));
          axios.defaults.headers.common = {
            Authorization: `${response?.data?.token}`,
          };
        }

        return response.data;
      });
  }


  // Login
  async login(email, password, token) {
    return axios
      .post(API_URL + "/login", {
        email,
        password,
      })
      .then(async (response) => {
        if (response.data?.success) {
          await localStorage.setItem(
            "user",
            JSON.stringify(response.data)
          );
          const token =
            (await this.getCurrentUser()) && this.getCurrentUser().token;
          axios.defaults.headers.common = { Authorization: `${token}` };
        }

        return response.data;
      });
  }


  // Forgot Password
  forgotPassword(email) {
    return axios.post(API_URL + "/forgot-password", {
      email,
    });
  }

  // Reset password
  resetPassword(token, password) {
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    return axios.post(API_URL + "/new-password", {
      password,
    });
  }

  // Current logged user data
  getCurrentUser() {
    getAuthorization();
    const token = JSON.parse(localStorage.getItem("user"))?.token;
    if (token) {
      return {
        token,
        success: true,
        user: jwt_decode(token),
      };
    }
  }

}

export default new AuthService();
