import React from 'react';
import regex from '../../utilities/regex';

const VerifyUser = (props) => {
    return (
        <div className="created-hired-form-component mt-5 mx-2 pt-5">
            <div className="d-flex justify-content-center align-items-center">
                <div className="verify-card p-5 mt-5">
                    <h1 className="text-center title">Job Placement Hired Student Form</h1>
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="w-75">
                            <div>
                                <div className="mt-5">
                                    <label className="mb-2" htmlFor="companyName">Your Email</label>
                                    <input type="email"
                                        className={`w-100 form-control input-gap mb-2 ${props.validUser ? 'border-success' : ''}`}
                                        onChange={(e) => props.handleVerifyEmail(e)}
                                        placeholder="hero@programminghero.com"
                                    />
                                    {props?.email && !regex.email.test(props?.email.trim()) && <span className="text-danger">Invalid email address</span> }
                                </div>
                            </div>

                            <button className="btn btn-primary-custom verify-btn w-100 mt-3"
                                onClick={() => props.submitAndVerify()}
                            >Verify</button>
                        </div>
                    </div>
                    <h1 className='text-primary fst-italic note-text'>NOTE:This email must be same as the email you used for programming hero course</h1>
                </div>
            </div>
        </div>
    );
};

export default VerifyUser;