import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import clientRouteSlice from "./Slicers/clientRouteSlice";
import togglerSlice from "./Slicers/togglerSlice";
import authSlice from "./Slicers/authSlice";
import companyForClientSlice from "./Slicers/companyForClient";

const reducers = combineReducers({
    clientRoute: clientRouteSlice,
    toggler: togglerSlice,
    auth: authSlice,
    companyInfo: companyForClientSlice
});

const persistConfig = {
    key: "root",
    whitelist: ["auth", "companyInfo"],
    blacklist: [],
    storage
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== "production",
    middleware: [thunk]
});

let persistor = persistStore(store);

export { persistor };

export default store;
