import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import userServices from '../../services/userServices';

// Token check
const TokenCheck = () => {
  const NavigateTo = useNavigate();
  const location = useLocation();
  const query = location.search.replace('?code=', '')
  const [resonseMessage, setResponseMessage] = useState({});

  useEffect(() => {
    userServices.TokenCheck({
      token: query
    }).then(res => {
      setResponseMessage(res.data)
      if (res.data?.redirect === true) {
        NavigateTo(`/reset-password?code=${res.data.token}`)
      }
    }).catch(error => {
      setResponseMessage(error.response.data)
    })

  }, [NavigateTo, query])

  return (
    <div className="mt-5 pt-5">
      <div className="container token-check-component justify-content-center d-flex align-items-center">
        <h4 className="">
          {resonseMessage?.message ? resonseMessage?.message : "Please wait few moment..."}
          {resonseMessage?.tryAgain && <Link to={`/forgot-password`}> Try Again</Link>}
        </h4>
      </div>
    </div>
  );
};

export default TokenCheck;