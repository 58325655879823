import React, { useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { Controller, useForm } from "react-hook-form";
import jobPosition from "../../components/data/jobPositions.json";
import batchNameData from "../../components/data/batchName.json";
import subjectData from "../../components/data/academicData/academicMajors.json";
import jobSources from "../../components/data/jobSources.json";
import countries from "../../components/data/countryData";
import bdDistricts from "../../components/data/bd-districts.json";
import swal from "sweetalert";
import hiredFormService from "../../services/hiredForm/hiredFormService";
import { useQuery } from "react-query";
import "../../scss/HiredForm/created-hired-form.scss";
import UploadFile from "./UploadFile";
import uploadImg from "../../assets/icons/upload-img.png"
import { CFormSelect } from "@coreui/react";
import VerifyNoticeModal from "./VerifyNoticeModal";
import regex from "../../utilities/regex";


const CreateHiredForm = (props) => {
    const { fullName, phone, email } = props.user;
    const { register, handleSubmit, watch, reset, control, formState: { errors } } = useForm();
    const [attachment, setAttachement] = useState([]);
    const [successNotice, setIsSuccessNotice] = useState({ edit: false });
    const [isUploading, setIsUploading] = useState(false);
    const [percentage, setPercentage] = useState(0);
    const previewImg = watch("image");

    const { data: hiredByCompanies } = useQuery(
        "hired-by-companies",
        hiredFormService.hiredByCompanies,
        {
            select: (res) => res.data?.data,
        }
    );

    const { data: existingInstitute } = useQuery(
        "existing-institute",
        hiredFormService.existingInstitutes,
        {
            select: (res) => res.data?.data,
        }
    );

    const config = {
        onUploadProgress: (data) => {
            let progress = Math.round((data.loaded * 100) / data.total)
            setPercentage(progress)
        }
    }

    const handleAddAttachment = (file) => {
        let formData = new FormData();
        formData.append("file", file);
        setIsUploading(true);

        hiredFormService.uploadAppointmentLetter(formData, config).then((response) => {
            setIsUploading(false)
            setAttachement([...attachment, response?.data?.data]);
            if (document.getElementById("file-input")) {
                document.getElementById("file-input").value = null;
            }
        })
            .catch((err) => {
                setIsUploading(false)
                swal("Failed to upload attachment!", err?.response?.data?.message, "error")
            })
    };


    const handleDeleteAttachment = (id) => {
        setPercentage(0)
        let attachmentList = attachment;
        setAttachement(attachmentList.filter((element) => element.fileId !== id));

        hiredFormService.deleteAttachmentProfileSummery(id).then((response) => {
            setAttachement(attachmentList.filter((element) => element.fileId !== id));
        });
    };

    const handleDownloadAttachment = (id) => {
        hiredFormService.downloadAppointmentLetter(id).then((response) => {
            const file = new Blob([response.data], { type: "application/pdf" });
            const fileURL = window.URL.createObjectURL(file);
            window.open(fileURL);
        });
    };

    const saveData = (data) => {
        const profile = data.image[0];
        const pdfFile = attachment[0];
        const formData = new FormData();
        formData.append("email", email);
        formData.append("fullName", fullName);
        formData.append("phone", phone);
        formData.append("companyName", data.companyName[0].label || data.companyName[0]);
        formData.append("position", data.position[0].label || data.position[0]);
        formData.append("batchName", data.batchName[0].label || data.batchName[0]);
        formData.append("instituteName", data.instituteName[0].label || data.instituteName[0]);
        formData.append("subject", data.subject[0].label || data.subject[0]);
        formData.append("jobSource", data.jobSource[0].label || data.jobSource[0]);
        formData.append("jobType", data.jobType);
        formData.append("description", data.description);
        formData.append("companyCountry", data.companyCountry);
        formData.append("companyCity", data.companyCity);
        formData.append("companyLocation", data.location);
        formData.append("companyLinkedinId", data.linkedin);
        formData.append("companyWebsite", data.website);
        formData.append("monthlySalary", data.salary);
        formData.append("startDate", data.startDate);
        formData.append("endDate", data.endDate);
        formData.append("currentlyWorkingThere", data.currentlyWorkingThere);
        formData.append("credit", data.credit);
        formData.append("appointmentLetter", JSON.stringify(pdfFile));
        formData.append("image", profile)

        hiredFormService
            .updateMyAdditionalData(formData)
            .then((res) => {
                if (res.data?.success) {
                    setIsSuccessNotice({ edit: true });
                    reset();
                }
            })
            .catch((err) => {
                swal("", err.response?.data?.msg, "error")
            });
    };

    return (
        <div className="container pt-5 mt-5">
            <div className="created-hired-form-component mt-5">
                <VerifyNoticeModal
                    openModal={successNotice}
                    label="success"
                />
                <div className="create-form-card py-5">
                    <h1 className="text-center title mb-5">Job Placement Hired Student Form</h1>
                    <div className='d-flex justify-content-center align-items-center'>
                        <form className="w-75" onSubmit={handleSubmit(saveData)}>
                            <h1 className="company-title mt-4">Job Information</h1>

                            <div className="d-lg-flex py-4">
                                <div className='profile d-flex align-items-center'>
                                    <div>
                                        <input type="file" id="profilePhoto" name="image"
                                            className="sr-only"
                                            {...register('image', { required: true })}
                                        />
                                        <label htmlFor="profilePhoto">
                                            {previewImg?.length > 0 ?
                                                <img className="preview-img cursor-pointer-event" src={previewImg?.length > 0 &&
                                                    URL?.createObjectURL(previewImg?.[0])} alt="" />
                                                :
                                                <img className="img-fluid profile-photo cursor-pointer-event" src={uploadImg} alt="avatar" />

                                            }

                                        </label>
                                        <br />
                                        {errors.image && <span className="text-danger ms-3">Image is required</span>}
                                    </div>
                                </div>

                                <div className="w-100 label-not-edit">
                                    <div className="row mt-4">
                                        <div className="col-md-6 col-sm-6">
                                            <div>
                                                <label>email address</label>
                                                <p>{email}</p>

                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                            <div>
                                                <label>full name</label>
                                                <p>{fullName}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-sm-6">
                                            <div>
                                                <label>phone number</label>
                                                <p>{phone}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div />
                                </div>
                            </div>
                            <div className="d-md-flex">
                                <div className="w-100">
                                    <label className="mb-2" htmlFor="companyName">Company Name </label>
                                    <Controller
                                        control={control}
                                        name="companyName"
                                        rules={{
                                            required: true, maxLength: {
                                                value: 30,
                                                message: "max length must be greater than 30 characters",
                                            }
                                        }}
                                        render={({ field: { onChange, onBlur, onClick, onEnter } }) => (
                                            <Typeahead
                                                placeholder="Type here"
                                                id="companyName"
                                                name="companyName"
                                                newSelectionPrefix="Click to save: "
                                                required
                                                allowNew
                                                onChange={onChange}
                                                onClick={onClick}
                                                onBlur={onBlur}
                                                onEnter={onEnter}
                                                options={hiredByCompanies || []}
                                                className="typeahead-gap"
                                            />
                                        )}
                                    />
                                    {(watch("companyName")?.[0]?.label || watch("companyName")?.[0])?.length > 100 && <span className="text-danger">max length must be greater than 100 characters</span>}
                                    {errors.companyName && <span className="text-danger">Company Name is required</span>}
                                </div>

                                <div className="ms-md-4 w-100">
                                    <label className="mb-2" htmlFor="position">Position </label>
                                    <Controller
                                        control={control}
                                        name="position"
                                        rules={{ required: true }}
                                        render={({ field: { onChange } }) => (
                                            <Typeahead
                                                placeholder="Type here"
                                                id="position"
                                                name="position"
                                                newSelectionPrefix="Click to save: "
                                                required
                                                allowNew
                                                onChange={onChange}
                                                options={jobPosition || []}
                                                className="typeahead-gap"
                                            />
                                        )}
                                    />
                                    {(watch("position")?.[0]?.label || watch("position")?.[0])?.length > 100 && <span className="text-danger">max length must be greater than 100 characters</span>}
                                    {errors.position && <span className="text-danger">Position is required</span>}

                                </div>
                            </div>

                            <div className="d-md-flex mt-3">
                                <div className="mt-3 w-100">
                                    <label className="mb-2" htmlFor="batchName">Batch Name</label>
                                    <Controller
                                        control={control}
                                        name="batchName"
                                        rules={{ required: true }}
                                        render={({ field: { onChange } }) => (
                                            <Typeahead
                                                placeholder="Type here"
                                                id="batchName"
                                                name="batchName"
                                                newSelectionPrefix="Click to save: "
                                                required
                                                allowNew
                                                onChange={onChange}
                                                options={batchNameData || []}
                                                className="typeahead-gap"
                                            />
                                        )}
                                    />
                                    {(watch("batchName")?.[0]?.label || watch("batchName")?.[0])?.length > 100 && <span className="text-danger">max length must be greater than 100 characters</span>}
                                    {errors.batchName && <span className="text-danger">batch Name is required</span>}
                                </div>

                                <div className="mt-3 ms-md-4 w-100">
                                    <label className="mb-2" htmlFor="instituteName">Institute Name </label>
                                    <Controller
                                        control={control}
                                        name="instituteName"
                                        rules={{ required: true }}
                                        render={({ field: { onChange } }) => (
                                            <Typeahead
                                                placeholder="Type here"
                                                id="instituteName"
                                                name="instituteName"
                                                newSelectionPrefix="Click to save: "
                                                required
                                                allowNew
                                                onChange={onChange}
                                                options={existingInstitute || []}
                                                className="typeahead-gap"
                                            />
                                        )}
                                    />
                                    {(watch("instituteName")?.[0]?.label || watch("instituteName")?.[0])?.length > 100 && <span className="text-danger">max length must be greater than 100 characters</span>}
                                    {errors.instituteName && <span className="text-danger">Institute Name is required</span>}

                                </div>
                            </div>

                            <div className="d-md-flex mt-4">
                                <div className="w-100">
                                    <label className="mb-2" >Subject</label>
                                    <Controller
                                        control={control}
                                        name="subject"
                                        rules={{ required: true }}
                                        render={({ field: { onChange } }) => (
                                            <Typeahead
                                                placeholder="Type here"
                                                id="subject"
                                                name="subject"
                                                newSelectionPrefix="Click to save: "
                                                required
                                                allowNew
                                                onChange={onChange}
                                                options={subjectData || []}
                                                className="typeahead-gap"
                                            />
                                        )}
                                    />
                                    {(watch("subject")?.[0]?.label || watch("subject")?.[0])?.length > 100 && <span className="text-danger">max length must be greater than 100 characters</span>}
                                    {errors.subject && <span className="text-danger">Subject Name is required</span>}
                                </div>

                                <div className="ms-md-4 w-100 mt-lg-0 mt-md-0 mt-3">
                                    <label className="mb-2" htmlFor="jobSource">How did you get this job?</label>
                                    <Controller
                                        control={control}
                                        name="jobSource"
                                        rules={{
                                            required: true
                                        }}
                                        render={({ field: { onChange } }) => (
                                            <Typeahead
                                                placeholder="Type here"
                                                id="jobSource"
                                                name="jobSource"
                                                newSelectionPrefix="Click to save: "
                                                required
                                                allowNew
                                                onChange={onChange}
                                                options={jobSources || []}
                                                className="typeahead-gap"
                                            />
                                        )}
                                    />
                                    {(watch("jobSource")?.[0]?.label || watch("jobSource")?.[0])?.length > 100 && <span className="text-danger">max length must be greater than 100 characters</span>}
                                    {errors.jobSource && <span className="text-danger">JobSource is required</span>}
                                </div>
                            </div>


                            <div className="mt-4 ">
                                <label className="mb-2">Job Type</label>
                                <div className="d-lg-flex justify-content-between align-items-center">
                                    <div className="card input-gap w-100">
                                        <div>
                                            <input
                                                {...register("jobType", { required: true })}
                                                className="form-check-input"
                                                type="radio"
                                                id="exampleRadios2"
                                                value="Intern"
                                            />
                                            <label className="form-check-label ms-2" htmlFor="exampleRadios2">
                                                Intern
                                            </label>
                                        </div>
                                    </div>

                                    <div className="card input-gap ms-lg-3 mt-lg-0 mt-2 w-100">
                                        <div>
                                            <input
                                                {...register("jobType", { required: true })}
                                                className="form-check-input"
                                                type="radio"
                                                id="exampleRadios5"
                                                value="Part-time"
                                            />
                                            <label className="form-check-label ms-2" htmlFor="exampleRadios5">
                                                Part-time
                                            </label>
                                        </div>
                                    </div>


                                    <div className="card input-gap ms-lg-3 mt-lg-0 mt-2 w-100">
                                        <div>
                                            <input
                                                {...register("jobType", { required: true })}
                                                className="form-check-input"
                                                type="radio"
                                                id="exampleRadios3"
                                                value="Full-time"
                                            />
                                            <label className="form-check-label ms-2" htmlFor="exampleRadios3">
                                                Full-time
                                            </label>
                                        </div>
                                    </div>

                                    <div className="card input-gap ms-lg-3 mt-lg-0 mt-2 w-100">
                                        <div>
                                            <input
                                                {...register("jobType", { required: true })}
                                                className="form-check-input"
                                                type="radio"
                                                id="exampleRadios4"
                                                value="Contractual"
                                            />

                                            <label className="form-check-label ms-2" htmlFor="exampleRadios4">
                                                Contractual
                                            </label>
                                        </div>
                                    </div>

                                </div>

                                {
                                    errors.jobType && <span className="text-danger">Job Type is required</span>
                                }

                            </div>


                            <div className="mt-4">
                                <label className="mb-2" htmlFor="">Description </label>
                                <textarea
                                    rows={5}
                                    placeholder="Write your text here"
                                    {...register("description", {
                                        required: true, maxLength: {
                                            value: 1000,
                                            message: "max length must be greater than 1000 characters",
                                        }
                                    })}
                                    className="form-control"
                                >
                                </textarea>

                                {errors?.description && <span className="text-danger">{errors.description.message ? errors.description.message : "Description is required"}</span>}

                            </div>


                            <div className="mt-5 row">
                                <div className="mb-4">
                                    <h1 className="company-title">Company Information</h1>
                                </div>

                                <div className="col-md-6">
                                    <label className="mb-2" htmlFor="FullName">Country</label>
                                    <CFormSelect
                                        {...register("companyCountry", { required: true })}
                                        className={`form-control input-gap`}
                                        autoComplete="off"
                                    >
                                        <option value="">Select</option>
                                        {countries?.map((country) => (
                                            <option key={country} value={country}>
                                                {country}
                                            </option>
                                        ))}
                                    </CFormSelect>
                                    {errors?.companyCountry && <span className="text-danger">Company Country is required</span>}

                                </div>


                                <div className="col-md-6 mt-lg-0 mt-md-0 mt-3">
                                    <label className="mb-2" htmlFor="companyCity">City</label>
                                    <CFormSelect
                                        {...register("companyCity", { required: true })}
                                        className={`form-control input-gap`}
                                        autoComplete="off"
                                    >
                                        <option value="">Select</option>
                                        {bdDistricts.districts?.map((districts) => (
                                            <option key={districts.id} value={districts.name}>
                                                {districts.name}
                                            </option>
                                        ))}
                                    </CFormSelect>
                                    {errors?.companyCity && <span className="text-danger">Company City is required</span>}

                                </div>
                            </div>

                            <div className="mt-4 row">
                                <div className="col-md-6">
                                    <label className="mb-2" htmlFor="FullName">Location</label>
                                    <input type="text" className="form-control input-gap" placeholder="Type here" {...register("location", {
                                        required: true, maxLength: {
                                            value: 100,
                                            message: "max length must be greater than 100 characters",
                                        }
                                    })} />
                                    {errors?.location && <span className="text-danger">{errors.location.message ? errors.location.message : 'Company location is required'}</span>}

                                </div>


                                <div className="col-md-6 mt-lg-0 mt-md-0 mt-3">
                                    <label className="mb-2" htmlFor="companyCity">Linkeding ID</label>
                                    <input
                                        type="text"
                                        placeholder="Type here"
                                        {...register("linkedin", {
                                            required: true, maxLength: {
                                                value: 100,
                                                message: "max length must be greater than 100 characters",
                                            },
                                            pattern: {
                                                value: regex.url,
                                                message: "Invalid Url !!",
                                            },
                                        })}
                                        className="form-control input-gap"
                                    />
                                    {errors?.linkedin && <span className="text-danger">{errors.linkedin.message ? errors.linkedin.message : "Company Linkedin is required"}</span>}

                                </div>
                            </div>


                            <div className="row">
                                <div className="col-md-6 mt-4">

                                    <label className="mb-2">Website</label>
                                    <input
                                        className="form-control input-gap"
                                        {...register("website", {
                                            required: true,
                                            maxLength: {
                                                value: 100,
                                                message: "max length must be greater than 100 characters",
                                            },
                                            pattern: {
                                                value: regex.url,
                                                message: "Invalid Url !!",
                                            },
                                        })}
                                        type="link"
                                        placeholder="Type here"
                                    />

                                    {errors?.website && <span className="text-danger">{errors.website.message ? errors.website.message : 'Website Link is required'}</span>}

                                </div>

                                <div className="col-md-6 mt-4">
                                    <label className="mb-2">Monthly Salary</label>
                                    <input
                                        className="form-control input-gap"
                                        {...register("salary", {
                                            required: true, maxLength: {
                                                value: 10,
                                                message: "max length must be greater than 10 characters",
                                            }
                                        })}
                                        type="number"
                                        placeholder="Type here"
                                    />

                                    {errors?.salary && <span className="text-danger">{errors.salary.message ? errors.salary.message : 'Monthly salary is required'}</span>}

                                </div>
                            </div>


                            <div className="mt-4 d-flex">
                                <input
                                    className="form-check-input"
                                    {...register("currentlyWorkingThere", { required: false })}
                                    type="checkbox"
                                    value={true}
                                    id="flexCheckChecked"
                                />
                                <label className="ms-2" htmlFor="flexCheckChecked">
                                    I am currently working in this role
                                </label>
                            </div>


                            <div className="mt-3 row">
                                <div className="col-md-6">
                                    <label className="mb-2" htmlFor="email">Start Date </label>
                                    <input
                                        type="date"
                                        {...register("startDate", { required: true })}
                                        className={`form-control input-gap ${errors?.startDate && "invalid-input"
                                            }`}
                                    />
                                    {errors?.startDate && <span className="text-danger">Start Date is required</span>}

                                </div>


                                <div className="col-md-6 mt-lg-0 mt-md-0 mt-3">
                                    <label className="mb-2" htmlFor="email">End Date</label>
                                    {(watch("currentlyWorkingThere") === undefined) ||
                                        watch("currentlyWorkingThere") ? (
                                        <p>Present</p>
                                    ) : (
                                        <>
                                            <input
                                                type="date"
                                                {...register("endDate", { required: true })}
                                                className={`form-control input-gap ${errors?.endDate && "invalid-input"
                                                    }`}
                                            />
                                            {errors?.endDate && <span className="text-danger">End Date is required</span>}

                                        </>
                                    )}
                                </div>
                            </div>

                            <div className="mt-4">
                                <label htmlFor="email">
                                    How Programming Heroes help you to build your career?
                                </label>

                                <textarea
                                    rows={5}
                                    placeholder="Write your text here.............."
                                    {...register("credit", {
                                        required: true, maxLength: {
                                            value: 1000,
                                            message: "max length must be greater than 1000 characters",
                                        }
                                    })}
                                    className='form-control mt-2'
                                >

                                </textarea>
                                {errors.credit && <span className='text-danger'>{errors.credit.message ? errors.credit.message : 'Credit is required'}</span>}
                            </div>


                            <div className="mt-4">
                                <label className="mb-3" htmlFor="myfile">Appointment Letter</label>
                                <UploadFile
                                    attachment={attachment}
                                    setAttachement={setAttachement}
                                    handleAddAttachment={handleAddAttachment}
                                    handleDownloadAttachment={handleDownloadAttachment}
                                    handleDeleteAttachment={handleDeleteAttachment}
                                    isUploading={isUploading}
                                    percentage={percentage}
                                />
                            </div>

                            <div className="mt-5 text-end">
                                <input disabled={isUploading || attachment.length === 0} className="btn btn-primary-custom w-100" type="submit" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateHiredForm;
