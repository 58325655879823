import React from 'react';
import ForgotPassForm from '../../components/Forms/ForgotPassForm/ForgotPassForm';

const ForgotPassword = () => {
    return (
        <>
            <ForgotPassForm
                label="Forgot password"
            />
        </>
    );
};

export default ForgotPassword;