import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  toggler: true
}

export const togglerSlice = createSlice({
  name: 'toggler',
  initialState,
  reducers: {
    setToggler: (state) => {
      state.toggler = !state.toggler
    }
  }
})

export const {
  setToggler,
} = togglerSlice.actions

export default togglerSlice.reducer