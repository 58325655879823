import axios from "axios";
import authService from "./auth/authService";

const API_URL = process.env.REACT_APP_API_URL + "user";

class UserService {
    constructor() {
        const token = authService.getCurrentUser() && authService.getCurrentUser().token;
        axios.defaults.headers.common = { Authorization: `${token}` };
    }

    // Get Current/logged user info
    getLoggedUserInfo = () => {
        return axios.get(API_URL + "/me");
    };

    //add manual user form dashboard
    addUser = (payload) => {
        return axios.post(API_URL + "/add-user", payload);
    };

    //get all user history
    getAllUser = (params) => {
        return axios.get(API_URL + "/get-users", {
            params
        });
    };

    //get all user history
    getSingleUser = (id) => {
        return axios.get(API_URL + "/get-user/" + id);
    };

    //update user history
    updateUser = (id, payload) => {
        return axios.patch(API_URL + "/update-user/" + id, payload);
    };

    //update profile
    updateUserProfile = (payload) => {
        return axios.patch(API_URL + "/update-profile/", payload);
    };

    //change password
    updateUserPassword = (payload) => {
        return axios.patch(API_URL + "/change-password/", payload);
    };

    //access denied
    deleteUser = (payload) => {
        return axios.patch(API_URL + "/delete-user/", payload);
    };

    // Token check
    TokenCheck = (payload) => {
        return axios.post(API_URL + `/check-forget-password-token`, payload);
    };

    //get client directory user
    usersForClientDirectory = () => {
        return axios.get(API_URL + "/users-for-client-directory/");
    };

    //get all students
    getAllStudents = (params) => {
        return axios.get(API_URL + "/students", { params });
    };
}

export default new UserService();
