import axios from "axios";
import authService from "../auth/authService";


const API_URL_WEB = process.env.REACT_APP_WEB_LOCAL_API_URL;
const API_URL = process.env.REACT_APP_API_URL + "got-hired";


class hiredFormService {
    constructor() {
        const token = authService.getCurrentUser() && authService.getCurrentUser().token;
        axios.defaults.headers.common = { Authorization: `${token}` };
    }

    //hired company info get
    hiredByCompanies = (params) => {
        return axios
            .get(API_URL + `/hired-by-companies`, { params })
    }

    //existing institute info get
    existingInstitutes = (params) => {
        return axios
            .get(API_URL + `/existing-institutes`, { params })
    }


    //verify requsted user
    verifyHiredStudent = (payload) => {
        return axios.post(API_URL + `/verify-hired-student`, payload)
    }

    // Update own Additional Data
    updateMyAdditionalData = (payload) => {
        return axios.post(API_URL + `/submit-hired-info`, payload,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
    };


    // Upload Appointment Letter
    uploadAppointmentLetter = (payload, config) => {
        return axios.post(API_URL + `/upload-appointment-letter`, payload,
            {
                ...config,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
    };

    //download letter
    downloadAppointmentLetter = (id) => {
        return axios
            .get(API_URL + `/download-appointment-letter/${id}`, {
                responseType: 'arraybuffer'
            })
    }

    // delete Appointment Letter
    deleteAttachmentProfileSummery = (id) => {
        return axios
            .delete(API_URL + `/delete-appointment-letter/${id}`)
    }

    //update single hired student entry 
    updateSingleHiredStudentEntry = (id, payload) => {
        return axios
            .patch(API_URL + `/update-single-hired-student-entry/${id}`, payload, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
    }

}

export default new hiredFormService();
