import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

// auth guard high order component 
const PrivateRoute: FC<{ children: JSX.Element }> = ({ children }) => {
    const location = useLocation();
    const userData = useSelector((state: any) => state.auth?.token);
    return userData?.success ? children : <Navigate to="/login" state={{ from: location }} replace />;
}
export default PrivateRoute;