import React, { useState } from 'react';
import CustomModal from '../../components/Custom/CustomModal';
import message from "../../assets/image/got-hired/warning.png";
import boomMessage from "../../assets/icons/boom.png";
import boomSuccess from "../../assets/icons/done-boom.png";
import { useNavigate } from 'react-router-dom';

const VerifyNoticeModal = (props) => {
    const navigateTo = useNavigate();
    const [closeModal, setCloseModal] = useState({ status: false });

    const handleChangeMode = () => {
        setCloseModal({ status: false });
        props.setEditMode(true)
    }
    const handleChangeRoute = () => {
        setCloseModal({ status: false });
        navigateTo("/")
    }

    return (
        <div>
            <CustomModal openModal={props?.openModal}
                closeModal={closeModal}
                customStyle={{ maxWidth: '480px' }}
            >
                <div className="created-hired-form-component">
                    <div className="verify-modal-component">
                        {
                            props.label === 'Invalid' &&

                            <div className="text-center">
                                <img src={message} alt="message" className="img-fluid" />
                                <h3 className="mt-3">তোমার ইমেইল টি খুঁজে পাওয়া যায়নি!</h3>
                                <p className="mt-3">তুমি যে ইমেইল দিয়ে প্রোগ্রামিং হিরো কোর্সে এনরোল করেছো, অবশ্যই সেই ইমেইল দিয়ে ইনফরমেশন সাবমিট করতে হবে</p>
                                <button onClick={() => setCloseModal({ status: false })} className="btn btn-primary-custom w-50 mt-3">Try Again</button>
                            </div>

                        }

                        {
                            props.label === 'Verifyed' &&

                            <div className="text-center">
                                <img src={boomMessage} alt="message" className="img-fluid" />
                                <h3 className="mt-3">Congratulations your email is verified</h3>
                                <p className="mt-3">There are many variations of passages of Lorem Ipsum available, but the majority .</p>
                                <button onClick={() => handleChangeMode()} className="btn btn-primary-custom w-50 mt-3">Let’s Go</button>
                            </div>

                        }
                        {
                            props.label === 'success' &&

                            <div className="text-center">
                                <img src={boomSuccess} alt="message" className="img-fluid" />
                                <h3 className="mt-3">Your message has been successfully send</h3>
                                <p className="mt-3">There are many variations of passages of Lorem Ipsum available, but the majority .</p>
                                <button onClick={() => handleChangeRoute()} className="btn btn-outline-danger w-50 mt-3">Visit Website</button>
                            </div>

                        }
                    </div>
                </div>
            </CustomModal>
        </div>
    );
};

export default VerifyNoticeModal;