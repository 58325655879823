import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    companyInfo: ""
};

export const companyForClient = createSlice({
    name: "singleCompanyInfo",
    initialState,
    reducers: {
        setCompanyInfo: (state, { payload }) => {
            state.companyInfo = payload;
        }
    }
});

export const { setCompanyInfo } = companyForClient.actions;

export default companyForClient.reducer;
