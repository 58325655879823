import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    clientRoute: {}
}

export const clientRouteSlice = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        loadClientRoutes: (state, { payload }) => {
            state.clientRoute = payload
        }
    }
})

export const {
    loadClientRoutes,
} = clientRouteSlice.actions

export default clientRouteSlice.reducer