import React, { useState } from 'react';
import "../../scss/HiredForm/hired-from.scss";
import hiredFormService from '../../services/hiredForm/hiredFormService';
import CreateHiredForm from './CreateHiredForm';
import VerifyNoticeModal from './VerifyNoticeModal';
import VerifyUser from './VerifyUser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import regex from '../../utilities/regex';

const HiredForm = () => {
    const [editMode, setEditMode] = useState(false);
    const [user, setUser] = useState({});
    const [validUser, setValidUser] = useState(false);
    const [email, setEmail] = useState("");
    const [inValidUserModel, setInvalidUserModel] = useState({ edit: false });

    const handleVerifyEmail = (e) => {
        setEmail(e.target.value)
    }

    const submitAndVerify = () => {
        const valid = regex.email.test(email);
        if (valid) {
            hiredFormService.verifyHiredStudent({ email })
                .then(res => {
                    if (res.data?.success) {
                        showSuccessMessage();
                        setValidUser(res.data?.success);
                        setUser(res.data?.data);
                    } else {
                        setInvalidUserModel({ edit: true });
                    }
                })
                .catch(err => {
                    if (err.response.status === 404) {
                        setInvalidUserModel({ edit: true });
                        setValidUser(err.response?.data?.success);
                    }

                })
        }
    }


    const showSuccessMessage = () => {
        toast.success('Congratulations your email is verified', {
            position: toast.POSITION.TOP_RIGHT
        });
        setEditMode(true);
    };

    return (
        <div className="hired-form-component">
            <ToastContainer />
            <div>
                {editMode ?
                    <CreateHiredForm
                        user={user}
                        setEditMode={setEditMode}
                    />
                    :
                    <VerifyUser
                        submitAndVerify={submitAndVerify}
                        handleVerifyEmail={handleVerifyEmail}
                        validUser={validUser}
                        email={email}
                    />
                }

                <VerifyNoticeModal
                    openModal={inValidUserModel}
                    label="Invalid"
                />

            </div>
        </div>
    );
};

export default HiredForm;