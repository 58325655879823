import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress, {
  circularProgressClasses,
} from '@mui/material/CircularProgress';


function FacebookCircularProgress(props) {
  return (
    <Box sx={{ position: 'relative' }}>


      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        }}
        size={80}
        thickness={3}
        {...props}
        value={100}
      />


      <CircularProgress
        variant="determinate"
        disableShrink
        sx={{
          color: (theme) => (theme.palette.mode === 'light' ? '#5A00DD' : '#308fe8'),
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={80}
        thickness={3}
        {...props}
      />

    </Box>
  );
}

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <FacebookCircularProgress value={props.value} />
      <Box
        sx={{
          top: "-4px",
          left: "5px",
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography sx={{ fontSize: "24px", color: '#424242'}} variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

export default function CircularProgressBar(props) {
  return <CircularProgressWithLabel value={props.progress} />;
}
